import { useEffect } from "react";

const useAutoScrollToBottom = (scrollTrigger) => {
  useEffect(() => {
    if (scrollTrigger) {
      console.log("Triggering auto-scroll to bottom.");

      // Use a timeout to allow for any DOM updates or to wait for full page load
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 500); // Adjust the timeout as needed
    }
  }, [scrollTrigger]);
};

export default useAutoScrollToBottom;
