import React, { useEffect, useRef, useState } from "react";
import AgeModal from "../verification-modal/VerificationModal";
import useDesktopCheck from "../../hooks/useDesktopCheck";

const AgeVerification = ({
  onVerified,
}: {
  onVerified: (verified: boolean) => void;
}) => {
  const [year, setYear] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isDesktop = useDesktopCheck();

  const yearInputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const calculateAge = (year: number) => {
    const currentYear = new Date().getFullYear();
    return currentYear - year;
  };

  const handleYearChange = (e: any) => {
    const newYear = e.target.value;
    setYear(newYear);
  };

  const verifyAge = (event: React.FormEvent) => {
    event.preventDefault();

    // Ensure year field is filled and is a number
    if (!year || isNaN(Number(year))) {
      alert("Please enter a valid year.");
      return;
    }

    const intYear = parseInt(year, 10);
    const age = calculateAge(intYear);

    if (age < 18) {
      setIsModalOpen(true);
      return;
    } else if (age > 100) {
      alert("Please enter a realistic year of birth.");
      return;
    }

    // If all checks pass
    onVerified(true);
  };

  useEffect(() => {
    if (year.length === 4) {
      formRef.current?.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  }, [year]);

  useEffect(() => {
    const originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    // Re-enable scrolling and restore the original overflow state when the component unmounts or when the modal is closed
    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  return (
    <div
      className="flex justify-center relative w-full h-screen bg-cover bg-center bg-no-repeat z-[60]"
      style={{
        backgroundImage: isDesktop
          ? "url('/images/Caraiman_age_desktop.png')"
          : "url('/images/Caraiman_age_mobile.png')",
      }}
    >
      {isModalOpen && (
        <AgeModal
          onConfirm={(isVerified: boolean, dob: Date | null) => {
            if (isVerified) {
              setIsModalOpen(false);
              onVerified(true);
            }
          }}
        />
      )}
      <div className="relative">
        <img
          className="mt-72 md:mt-28 z-20 max-h-[300px] sm:max-h-[500px] md:max-h-[750px]"
          src="/images/Caraiman_age-placuta.png"
          alt="age gate"
        />
        <form
          ref={formRef}
          onSubmit={verifyAge}
          className="flex flex-col items-center absolute left-[4rem] sm:left-[7.7rem] md:left-48 top-[23rem] sm:top-[26.5rem] md:top-[20.5rem] z-50"
        >
          <div className="flex mb-4">
            <input
              ref={yearInputRef}
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="AAAA"
              value={year}
              onChange={handleYearChange}
              maxLength={4}
              className="custom-number-input sm:p-2 rounded text-4xl sm:text-5xl md:text-7xl max-w-24 sm:max-w-32 md:max-w-44 text-center text-primary-green placeholder:text-primary-green bg-transparent focus:outline-none focus:ring-0 focus:border-transparent"
            />
          </div>
          <button
            type="submit"
            className="p-2 bg text-transparent rounded bg-transparent hidden"
          >
            Verify Age
          </button>
        </form>
      </div>
      <div className="absolute bottom-0 left-0 right-0 flex flex-col min-[1420px]:flex-row items-start lg:items-center justify-center p-2 md:justify-between z-20 ml-6 lg:ml-0">
        <div className="flex flex-col lg:flex-row items-start lg:items-center">
          <p className="order-2 lg:order-none tail-shadow4 lg:tail-shadow text-secondary-grey-light text-xs md:text-xl font-sans font-semibold z-20 text-left lg:text-center">
            Consumă Caraiman în mod responsabil.
          </p>
          <div className="tail-shadow3">
            <img
              className="order-1 lg:order-none tail-shadow mb-2 lg:ml-2 w-16 lg:w-24 z-20"
              src="/images/icons2.png"
              alt="icons"
            />
          </div>
        </div>
        <p className="tail-shadow2 text-secondary-grey-light text-xs md:text-xl font-sans font-semibold z-20 text-left lg:text-center mb-10 lg:mb-0">
          Pagina oficială Caraiman este doar pentru adulți peste 18 ani.
          <br className="flex min-[1100px]:hidden" /> Te rugăm să nu o distribui
          persoanelor sub 18 ani.
        </p>
      </div>
    </div>
  );
};

export default AgeVerification;
