import React, { useEffect, useState } from "react";
import Navbar from "./components/navbar/navbar";
import AgeVerification from "./components/age-verification/AgeVerification";
import useAutoScrollToBottom from "./scroll";
import useDesktopCheck from "./hooks/useDesktopCheck";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useDesktopCheck();

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [playAnimation, setPlayAnimation] = useState<boolean>(() => {
    const animationPlayed = localStorage.getItem("animationPlayed");
    return !animationPlayed; // Return true if the flag isn't set, meaning the animation hasn't been played
  });

  const getShowMainContent = () => {
    const now = new Date().getTime();
    const showMainContentData = localStorage.getItem("showMainContent");
    if (showMainContentData) {
      const { value, timestamp } = JSON.parse(showMainContentData);
      // Check if 30 minutes have passed (30 minutes * 60 seconds * 1000 milliseconds)
      if (now - timestamp < 30 * 60 * 1000) {
        return value;
      }
    }
    return false;
  };

  const [showMainContent, setShowMainContent] =
    useState<boolean>(getShowMainContent());

  useAutoScrollToBottom(true);

  const handleVerification = (verified: boolean) => {
    localStorage.setItem("ageVerified", "true");
    setIsVerified(verified);
  };

  useEffect(() => {
    const ageVerified = localStorage.getItem("ageVerified");
    setIsVerified(ageVerified === "true");
  }, []);

  useEffect(() => {
    // Save showMainContent to localStorage with a timestamp
    localStorage.setItem(
      "showMainContent",
      JSON.stringify({
        value: showMainContent,
        timestamp: new Date().getTime(),
      })
    );
  }, [showMainContent]);

  useEffect(() => {
    if (showMainContent && playAnimation) {
      // Assume the animation duration is 1s, so set the flag in localStorage after that
      setTimeout(() => {
        localStorage.setItem("animationPlayed", "true"); // Set a flag in localStorage
        setPlayAnimation(false); // Update state to reflect that the animation has now been played
      }, 1000); // Adjust timeout to match your animation duration
    }
  }, [showMainContent, playAnimation]);

  useEffect(() => {
    setTimeout(() => {
      const handleScroll = () => {
        const scrollHeight = document.body.scrollHeight - window.innerHeight;
        // Calculate scroll position from the bottom
        const scrolledFromBottom = scrollHeight - window.scrollY;
        // Normalize scroll progress (0 at the bottom, 1 at the top)
        const scrollProgress = scrolledFromBottom / scrollHeight;

        const animDiv = document.getElementById("anim");
        const leftTreesUp = document.querySelector(
          "img[src='/images/animatie/Caraiman_scroll_brad_stanga_sus.png']"
        ) as HTMLElement;
        const leftTreesMid = document.querySelector(
          "img[src='/images/animatie/Caraiman_scroll_brad_stanga_mid.png']"
        ) as HTMLElement;
        const leftTreesMid2 = document.querySelector(
          "img[src='/images/animatie/Caraiman_scroll_brad_stanga_mid2.png']"
        ) as HTMLElement;
        const leftTreesBottom = document.querySelector(
          "img[src='/images/animatie/Caraiman_scroll_brad_stanga_jos.png']"
        ) as HTMLElement;
        const rightTreesUp = document.querySelector(
          "img[src='/images/animatie/Caraiman_scroll_brad_dreapta_sus.png']"
        ) as HTMLElement;
        const rightTreesMid = document.querySelector(
          "img[src='/images/animatie/Caraiman_scroll_brad_dreapta_mid.png']"
        ) as HTMLElement;
        const rightTreesMid2 = document.querySelector(
          "img[src='/images/animatie/Caraiman_scroll_brad_dreapta_mid2.png']"
        ) as HTMLElement;
        const rightTreesBottom = document.querySelector(
          "img[src='/images/animatie/Caraiman_scroll_brad_dreapta_jos.png']"
        ) as HTMLElement;

        //mobile selectors

        // Assuming 'threshold' is defined elsewhere and represents the point at which effects start
        const scalingFactor = 0.4; // Adjust if you want to scale more than 1 based on scrollProgress
        const scale = 1 + scalingFactor * scrollProgress; // Scale effect

        if (animDiv) {
          // Calculate the exponential curve for the translation effect
          // x is the scrollProgress in this context
          const exponentialCurve = Math.exp(2 * scrollProgress - 3);

          // Since the exponential function can produce a wide range of outputs, you may want to scale it
          // to fit within your desired translation range. First, let's understand the range of our function:
          // At scrollProgress = 0 (start), expCurve = e^(-3) ≈ 0.05
          // At scrollProgress = 1 (end), expCurve = e^(-1) ≈ 0.37
          // You'll want to normalize this range to fit within your maxTranslationDistance

          // Define the range of your exponential function based on scrollProgress of 0 to 1
          const minExpOutput = Math.exp(-3); // Output at scrollProgress = 0
          const maxExpOutput = Math.exp(-1); // Output at scrollProgress = 1
          const maxTranslationDistance = scrollHeight * 0.2; // Define the maximum translation distance
          // Calculate the normalized translation distance
          const scaledTranslationDistance =
            ((exponentialCurve - minExpOutput) /
              (maxExpOutput - minExpOutput)) *
            maxTranslationDistance;

          // Optionally, make visibility of main content dependent on reaching the max translation distance
          if (scaledTranslationDistance >= maxTranslationDistance) {
            setShowMainContent(true);
          }

          animDiv.style.transform = `scale(${scale}) translateY(${scaledTranslationDistance}px)`;
        }

        if (
          leftTreesUp &&
          leftTreesMid &&
          leftTreesMid2 &&
          leftTreesBottom &&
          rightTreesUp &&
          rightTreesMid &&
          rightTreesMid2 &&
          rightTreesBottom
        ) {
          // Parallax effect: slower than the main scroll
          const parallaxOffset = scrolledFromBottom * 0.3; // Adjust the 0.2 value to control the speed
          const parallaxOffsetHorizontalBottom = parallaxOffset * 1.5;
          const parallaxOffsetHorizontalMid = parallaxOffset * 1.2;
          const parallaxOffsetHorizontalMid2 = parallaxOffset * 0.9;
          const parallaxOffsetHorizontalUp = parallaxOffset * 0.6;

          leftTreesUp.style.transform = `translateY(${parallaxOffset}px) translateX(${-parallaxOffsetHorizontalUp}px)`;
          leftTreesMid2.style.transform = `translateY(${parallaxOffset}px) translateX(${-parallaxOffsetHorizontalMid2}px)`;
          leftTreesMid.style.transform = `translateY(${parallaxOffset}px) translateX(${-parallaxOffsetHorizontalMid}px)`;
          leftTreesBottom.style.transform = `translateY(${parallaxOffset}px) translateX(${-parallaxOffsetHorizontalBottom}px)`;
          rightTreesUp.style.transform = `translateY(${parallaxOffset}px) translateX(${parallaxOffsetHorizontalUp}px)`;
          rightTreesMid2.style.transform = `translateY(${parallaxOffset}px) translateX(${parallaxOffsetHorizontalMid2}px)`;
          rightTreesMid.style.transform = `translateY(${parallaxOffset}px) translateX(${parallaxOffsetHorizontalMid}px)`;
          rightTreesBottom.style.transform = `translateY(${parallaxOffset}px) translateX(${parallaxOffsetHorizontalBottom}px)`;
        } else {
          const leftTreesUpMob = document.querySelector(
            "img[src='/images/animatie-mobile/stg_sus.png']"
          ) as HTMLElement;
          const leftTreesMid2Mob = document.querySelector(
            "img[src='/images/animatie-mobile/stg_mid2.png']"
          ) as HTMLElement;
          const leftTreesMidMob = document.querySelector(
            "img[src='/images/animatie-mobile/stg_mid.png']"
          ) as HTMLElement;
          const leftTreesBottom2Mob = document.querySelector(
            "img[src='/images/animatie-mobile/stg_jos2.png']"
          ) as HTMLElement;
          const leftTreesBottomMob = document.querySelector(
            "img[src='/images/animatie-mobile/stg_jos.png']"
          ) as HTMLElement;
          const rightTreesUpMob = document.querySelector(
            "img[src='/images/animatie-mobile/dr_sus.png']"
          ) as HTMLElement;
          const rightTreesMid2Mob = document.querySelector(
            "img[src='/images/animatie-mobile/dr_mid2.png']"
          ) as HTMLElement;
          const rightTreesMidMob = document.querySelector(
            "img[src='/images/animatie-mobile/dr_mid.png']"
          ) as HTMLElement;
          const rightTreesBottom2Mob = document.querySelector(
            "img[src='/images/animatie-mobile/dr_jos2.png']"
          ) as HTMLElement;
          const rightTreesBottomMob = document.querySelector(
            "img[src='/images/animatie-mobile/dr_jos.png']"
          ) as HTMLElement;

          const parallaxOffset = scrolledFromBottom * 0.2; // Adjust the 0.2 value to control the speed
          const parallaxOffsetHorizontalBottom = parallaxOffset * 1.5;
          const parallaxOffsetHorizontalBottom2 = parallaxOffset * 1.3;
          const parallaxOffsetHorizontalMid = parallaxOffset * 1.1;
          const parallaxOffsetHorizontalMid2 = parallaxOffset * 0.7;
          const parallaxOffsetHorizontalUp = parallaxOffset * 0.4;
          if (
            leftTreesUpMob &&
            leftTreesMid2Mob &&
            leftTreesMidMob &&
            leftTreesBottom2Mob &&
            leftTreesBottomMob &&
            rightTreesUpMob &&
            rightTreesMid2Mob &&
            rightTreesMidMob &&
            rightTreesBottom2Mob &&
            rightTreesBottomMob
          ) {
            leftTreesUpMob.style.transform = `translateY(${parallaxOffset}px) translateX(${-parallaxOffsetHorizontalUp}px)`;
            leftTreesMid2Mob.style.transform = `translateY(${parallaxOffset}px) translateX(${-parallaxOffsetHorizontalMid2}px)`;
            leftTreesMidMob.style.transform = `translateY(${parallaxOffset}px) translateX(${-parallaxOffsetHorizontalMid}px)`;
            leftTreesBottom2Mob.style.transform = `translateY(${parallaxOffset}px) translateX(${-parallaxOffsetHorizontalBottom2}px)`;
            leftTreesBottomMob.style.transform = `translateY(${parallaxOffset}px) translateX(${-parallaxOffsetHorizontalBottom}px)`;
            rightTreesUpMob.style.transform = `translateY(${parallaxOffset}px) translateX(${parallaxOffsetHorizontalUp}px)`;
            rightTreesMid2Mob.style.transform = `translateY(${parallaxOffset}px) translateX(${parallaxOffsetHorizontalMid2}px)`;
            rightTreesMidMob.style.transform = `translateY(${parallaxOffset}px) translateX(${parallaxOffsetHorizontalMid}px)`;
            rightTreesBottom2Mob.style.transform = `translateY(${parallaxOffset}px) translateX(${parallaxOffsetHorizontalBottom2}px)`;
            rightTreesBottomMob.style.transform = `translateY(${parallaxOffset}px) translateX(${parallaxOffsetHorizontalBottom}px)`;
          }
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, 1000);
  }, []);

  return (
    <div className={`${!showMainContent ? "overflow-hidden" : ""} relative`}>
      {showMainContent && (
        <div className={playAnimation ? "fade-in-animation" : ""}>
          <Navbar />
          <main>{children}</main>
          <div id="consent_blackbar"></div>
          <div className="absolute bottom-8 lg:bottom-10 left-0 right-0 flex flex-row items-center justify-center px-2 md:justify-start z-20">
            <div id="teconsent" />
          </div>
        </div>
      )}

      {!isVerified && (
        <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex justify-center items-center">
          <AgeVerification onVerified={handleVerification} />
        </div>
      )}
      {!showMainContent && (
        <div
          className="z-[100] scroll-cursor h-[1600px] custom-sm:h-[2400px] custom-md:h-[2700px] lg:h-[2000px]"
          id="anim"
        >
          {isDesktop ? (
            <>
              <img
                className="absolute bottom-0 w-full h-full object-cover overflow-x-hidden"
                src="/images/animatie/background.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-left-[45%] custom-lg:-left-[40%] custom-lgx:-left-[25%] custom-xl:-left-[22%] custom-2xl:left-0 absolute bottom-[1300px] overflow-x-hidden"
                src="/images/animatie/Caraiman_scroll_brad_stanga_sus.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-left-[42%] custom-lgx:-left-[28%] custom-xl:-left-[22%] custom-2xl:left-0 absolute bottom-[1000px] object-cover overflow-x-hidden"
                src="/images/animatie/Caraiman_scroll_brad_stanga_mid2.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-left-[40%] custom-lgx:-left-[30%] custom-2xl:-left-[5%] absolute bottom-[500px] object-cover overflow-x-hidden"
                src="/images/animatie/Caraiman_scroll_brad_stanga_mid.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-left-[400px] custom-lgx:-left-[300px] custom-2xl:left-0 absolute bottom-0 overflow-x-hidden"
                src="/images/animatie/Caraiman_scroll_brad_stanga_jos.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-right-[48%] custom-lgx:-right-[45%] custom-xl:-right-[38%] custom-2xl:-right-[15%] absolute bottom-[1300px] object-cover overflow-x-hidden"
                src="/images/animatie/Caraiman_scroll_brad_dreapta_sus.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="right-48 custom-lgx:right-56 custom-xl:right-64 custom-2xl:right-10 h-[800px] absolute bottom-[1000px] object-cover overflow-x-hidden"
                src="/images/animatie/Caraiman_scroll_brad_dreapta_mid2.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-right-[45%] custom-lgx:-right-[40%] custom-xl:-right-[31%] custom-2xl:-right-[5%] absolute bottom-[850px] object-cover overflow-x-hidden"
                src="/images/animatie/Caraiman_scroll_brad_dreapta_mid.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-right-[700px] custom-lgx:-right-[700px] custom-xl:-right-[650px] custom-2xl:-right-[100px] absolute -bottom-5 h-[1800px] custom-lg:h-full object-cover overflow-x-hidden"
                src="/images/animatie/Caraiman_scroll_brad_dreapta_jos.png"
                alt="Descriptive Alt Text"
              />
            </>
          ) : (
            <>
              <img
                className="absolute bottom-0 w-full h-full object-cover overflow-x-hidden"
                src="/images/animatie-mobile/mobile_background.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-left-36 absolute bottom-[70%] h-[400px] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/stg_sus.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-left-36 absolute bottom-[55%] h-[500px] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/stg_mid2.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-left-28 absolute bottom-[35%] h-[550px] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/stg_mid.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="left-0 absolute bottom-64 h-[600px] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/stg_jos2.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-left-12 absolute -bottom-5 h-[700px] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/stg_jos.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-right-40 absolute bottom-[70%] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/dr_sus.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-right-24 absolute bottom-[63%] h-[550px] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/dr_mid2.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-right-10 absolute bottom-[35%] h-[600px] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/dr_mid.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="-right-8 absolute bottom-64 h-[750px] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/dr_jos2.png"
                alt="Descriptive Alt Text"
              />
              <img
                className="right-0 absolute -bottom-5 h-[600px] object-cover overflow-x-hidden"
                src="/images/animatie-mobile/dr_jos.png"
                alt="Descriptive Alt Text"
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Layout;
